import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    class: "bp-navbar-main navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl",
    id: "navbarBlur",
    "navbar-scroll": "true"
};
const _hoisted_2 = {
    class: "container-fluid py-1 px-3",
    style: { "border-bottom": "0 !important" }
};
const _hoisted_3 = { class: "btn-show-sidebar" };
const _hoisted_4 = {
    class: "collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4",
    id: "navbar"
};
const _hoisted_5 = { class: "navbar-nav justify-content-end" };
const _hoisted_6 = { class: "nav-link text-body font-weight-bold px-2" };
const _hoisted_7 = { class: "list-group list-group-flush" };
const _hoisted_8 = ["onClick"];
const _hoisted_9 = { class: "nav-link text-body font-weight-bold px-2" };
const _hoisted_10 = { class: "d-sm-inline d-none p-2" };
const _hoisted_11 = { class: "list-group list-group-flush" };
import { ref, onMounted } from "vue";
import Auth from "@/api/Auth";
import Lang from "@/plugins/Lang";
import lang from "@/plugins/Lang";
import User from "@/module/User";
import { Tooltip } from "bootstrap/dist/js/bootstrap.min";
export default /*@__PURE__*/ _defineComponent({
    __name: 'MainNavbar',
    emits: ["onShowSidebar"],
    setup(__props, { emit: __emit }) {
        const tooltip = ref([]);
        const userEmail = ref(Auth.getEmail());
        const instanceProd = ref(User.getMe().instanceProd);
        const selectedLanguage = ref(Lang.getNameLocale());
        const visibleLanguagePopover = ref(false);
        const visibleProfilePopover = ref(false);
        const languageList = ref(Lang.getLanguages());
        const emit = __emit;
        const selectLanguage = (code) => {
            lang.setLocale(code);
            window.location.reload();
        };
        function showSidebar() {
            emit("onShowSidebar");
        }
        onMounted(async () => {
            new Tooltip(document.body, {
                selector: "[data-bs-toggle='tooltip']",
            });
        });
        return (_ctx, _cache) => {
            const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
            const _component_router_link = _resolveComponent("router-link");
            return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("a", {
                            href: "#",
                            onClick: _withModifiers(showSidebar, ["prevent"]),
                            class: "btn btn-link btn-extra-sm"
                        }, [
                            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-bars" })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "ms-md-auto pe-md-3 d-flex align-items-center" }, [
                            _createElementVNode("div", { class: "input-group" })
                        ], -1)),
                        _createElementVNode("ul", _hoisted_5, [
                            _createElementVNode("li", {
                                class: "px-3 nav-item d-flex align-items-center language-select",
                                onMouseover: _cache[2] || (_cache[2] = ($event) => (visibleLanguagePopover.value = true)),
                                onMouseleave: _cache[3] || (_cache[3] = ($event) => (visibleLanguagePopover.value = false))
                            }, [
                                _createElementVNode("a", _hoisted_6, _toDisplayString(selectedLanguage.value), 1),
                                _createElementVNode("div", {
                                    onMouseover: _cache[0] || (_cache[0] = ($event) => (visibleLanguagePopover.value = true)),
                                    onMouseleave: _cache[1] || (_cache[1] = ($event) => (visibleLanguagePopover.value = false)),
                                    class: _normalizeClass(["language-list", visibleLanguagePopover.value ? 'show' : ''])
                                }, [
                                    _createElementVNode("ul", _hoisted_7, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(languageList.value, (language) => {
                                            return (_openBlock(), _createElementBlock("li", {
                                                class: "list-group-item",
                                                onClick: ($event) => (selectLanguage(language.code)),
                                                key: language.code
                                            }, _toDisplayString(language.name), 9, _hoisted_8));
                                        }), 128))
                                    ])
                                ], 34)
                            ], 32),
                            _createElementVNode("li", {
                                class: "nav-item d-flex align-items-center language-select",
                                onMouseover: _cache[6] || (_cache[6] = ($event) => (visibleProfilePopover.value = true)),
                                onMouseleave: _cache[7] || (_cache[7] = ($event) => (visibleProfilePopover.value = false))
                            }, [
                                (instanceProd.value)
                                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                                        key: 0,
                                        icon: "fa-solid fa-triangle-exclamation",
                                        title: "Вы используете продуктивную версию",
                                        "data-bs-toggle": "tooltip",
                                        "data-bs-placement": "top",
                                        ref_key: "tooltip",
                                        ref: tooltip,
                                        style: { "color": "#cd4e1e" }
                                    }, null, 512))
                                    : _createCommentVNode("", true),
                                _createElementVNode("a", _hoisted_9, [
                                    _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-circle-user" }),
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(userEmail.value), 1)
                                ]),
                                (!_unref(User).haveCatalogGroups())
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        onMouseover: _cache[4] || (_cache[4] = ($event) => (visibleProfilePopover.value = true)),
                                        onMouseleave: _cache[5] || (_cache[5] = ($event) => (visibleProfilePopover.value = false)),
                                        class: _normalizeClass(["language-list", visibleProfilePopover.value ? 'show' : ''])
                                    }, [
                                        _createElementVNode("ul", _hoisted_11, [
                                            _createVNode(_component_router_link, {
                                                to: "/profile/setting",
                                                class: "list-group-item"
                                            }, {
                                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                    _createTextVNode(" Настройки ")
                                                ])),
                                                _: 1
                                            })
                                        ])
                                    ], 34))
                                    : _createCommentVNode("", true)
                            ], 32)
                        ])
                    ])
                ])
            ]));
        };
    }
});
