import Axios from "axios";
import Router from "../binaplan/router";
import Lang from "@/plugins/Lang";
import Auth from "@/api/Auth";
import { notify } from "@kyvg/vue3-notification";
class Api {
    constructor() {
        this.createRequestHandler();
        this.events();
    }
    httpRequest;
    createRequestHandler() {
        this.httpRequest = Axios.create(this.getConfig());
    }
    getConfig() {
        const token = localStorage.getItem(this.accessTokenKey);
        let config;
        if (token) {
            this.config.headers.Authorization = `Bearer ${token}`;
            config = this.config;
        }
        else {
            config = this.configWithoutAuth;
        }
        return config;
    }
    events() {
        this.httpRequest.interceptors.response.use((res) => {
            return res;
        }, (err) => {
            if (err.response.status === 401) {
                if (err.response.data.message === "Token has expired") {
                    return Axios.post(process.env.VUE_APP_BASE_HOST + "api/auth/refresh", {}, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem(this.accessTokenKey),
                        },
                    })
                        .then((res) => {
                        localStorage.setItem("token", res.data.data.accessToken);
                        const header = err.config.headers;
                        header.Authorization = "Bearer " + res.data.data.accessToken;
                        err.config.headers = header;
                        this.createRequestHandler();
                        // double request
                        return this.httpRequest.request(err.config);
                    })
                        .catch((err) => {
                        if (err.response.status === 405) {
                            if (err.response.data.data.type === "expired") {
                                notify({
                                    type: "error",
                                    title: Lang.get("globals.default_error_title"),
                                    text: err.response.data.data.message,
                                });
                                Auth.removeToken();
                                Router.push("/login");
                            }
                        }
                        return null;
                    });
                }
                else {
                    this.unsetToken();
                    if (Router.currentRoute.value.path !== "/login") {
                        Router.push("/login")
                            .then((r) => {
                            console.warn("Error redirect: " + r);
                        })
                            .catch((r) => {
                            console.warn("Error redirect: " + r);
                        });
                        err.data = err.response.data;
                        return err;
                    }
                }
            }
            else if (err.response.status === 422 || err.response.status === 419) {
                return err.response;
            }
            else if (err.response.status === 412 || err.response.status === 428) {
                notify({
                    type: "error",
                    title: Lang.get("globals.default_error_title"),
                    text: err.response.data.data,
                });
            }
            else if (err.response.status === 413) {
                notify({
                    type: "error",
                    title: "REQUEST ENTITY TOO LARGE NGINX",
                    text: err.response.data.message,
                });
            }
            else if (err.response.status === 404) {
                /*
                                    notify({
                                      type: "error",
                                      title: "404 Not found",
                                      text: err.response.data.data,
                                    });*/
                err.data = err.response.data;
                return err;
            }
            else if (err.response.status === 429) {
                err.data = err.response.data;
                return err;
            }
            else if (err.response.status === 500) {
                notify({
                    type: "error",
                    title: "500 Server Error",
                    text: err.response.data.message ?? Lang.get("api.error_server"),
                });
                return err;
            }
            else {
                err.data = err.response.data;
                return err;
            }
        });
    }
    unsetToken() {
        localStorage.removeItem(this.accessTokenKey);
        localStorage.removeItem("email");
    }
    config = {
        baseURL: process.env.VUE_APP_BASE_HOST + "api",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "",
        },
    };
    configWithoutAuth = {
        baseURL: process.env.VUE_APP_BASE_HOST + "api",
        headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
        },
    };
    accessTokenKey = "token";
}
export default new Api();
