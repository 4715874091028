export default class Template {
    static lastCatalogName = "";
    static getFullPathImage(path) {
        return process.env.VUE_APP_BASE_HOST + process.env.VUE_APP_STORAGE + path;
    }
    static getCatalogTitlePage(catalogName) {
        return catalogName.length > 0
            ? ` / ${catalogName}`
            : Template.lastCatalogName.length > 0
                ? ` / ${Template.lastCatalogName}`
                : "";
    }
    static getBackgroundColor(hex) {
        return "background-color: " + hex + ";";
    }
    static getBackgroundImage(path) {
        return "background-image: url(" + this.getFullPathImage(path) + ");";
    }
    static getRouteId(param) {
        const id = typeof param === "undefined" ? "0" : param.toString();
        const routeId = typeof id === "undefined" ? "0" : id.replace(/\D/g, "");
        return routeId.length === 0 ? 0 : parseInt(routeId);
    }
    static getTexture(path) {
        path = path === null || typeof path === "undefined" ? "" : path;
        if (path.length === 0) {
            return "";
        }
        return path[0] === "#"
            ? this.getBackgroundColor(path)
            : this.getBackgroundImage(path);
    }
}
// Template();
