import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref } from "vue";
const _hoisted_1 = { class: "layout__title-page col-sm-12 mt-sm-0 mt-4" };
const _hoisted_2 = { class: "float-start mt-sm-0 mt-4" };
const _hoisted_3 = {
    class: "float-end",
    style: { "font-size": "18px", "font-weight": "500", "margin-top": "5px" }
};
const _hoisted_4 = {
    key: 0,
    style: { "color": "red" }
};
const _hoisted_5 = {
    key: 0,
    class: "float-end"
};
import { onMounted, ref } from "vue";
import Router from "@/binaplan/router/index";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TitlePageContainer',
    props: {
        titlePage: { type: String, default: "" },
        changeForm: { type: Boolean, default: false },
        backUrl: { type: String, default: "" },
        backButton: { type: String, default: "" },
        hash: { type: String, default: "" },
        company_id: { type: Number, default: NaN },
    },
    setup(__props) {
        const props = __props;
        const backParam = ref({
            path: props.backUrl,
            hash: "",
            query: isNaN(props.company_id) ? {} : { company_id: props.company_id },
        });
        onMounted(() => {
            if (typeof props.hash !== "undefined" && props.hash.length > 0) {
                backParam.value.hash = props.hash;
            }
            if (!isNaN(props.company_id) && props.company_id >= 0) {
                backParam.value.query.company_id = props.company_id;
            }
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "extended-buttons-before", { class: "float-start" }),
                    _createElementVNode("h1", _hoisted_3, [
                        _createTextVNode(_toDisplayString(__props.titlePage) + " ", 1),
                        (__props.changeForm)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _renderSlot(_ctx.$slots, "extended-buttons-after", { class: "float-end" }),
                (__props.backButton.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("a", {
                            onClick: _cache[0] || (_cache[0] = ($event) => (_unref(Router).push(backParam.value))),
                            class: "btn btn-link btn-sm"
                        }, _toDisplayString(__props.backButton), 1)
                    ]))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
