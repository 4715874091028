import defaultRequest from "@/api/DefaultRequest";
class AutoKitchen {
    constructor() {
        // .
    }
    addCollection(data) {
        return defaultRequest.post("auto-kitchen/backend/collections/", {
            data,
        });
    }
    async addRequest(code, data, testRequestId = 0) {
        const token = await this.getToken();
        return defaultRequest.postWithToken(`public/auto-kitchen/${code}/answers/?_rid=${testRequestId}`, {
            collection_id: 36,
            data,
        }, false, token.getResponse());
    }
    async getToken() {
        return await defaultRequest.postWithToken("public/auto-kitchen/token", {
            username: "test@mail.ru",
            password: "binaplan-test",
            domain: "localhost:8080",
        });
    }
    getCollections() {
        return defaultRequest.get(`auto-kitchen/backend/collections/`);
    }
    getCollection(id) {
        return defaultRequest.get(`auto-kitchen/backend/collections/${id}`);
    }
    getImageGenerate(id) {
        return defaultRequest.get(`public/auto-kitchen/requests/${id}/status`);
    }
    getCollectionModules(id) {
        return defaultRequest.get(`auto-kitchen/backend/collections/${id}/models`);
    }
    removeObjectSceneWebPlanner(id) {
        return defaultRequest.post(`public/auto-kitchen/requests/${id}/entities`, {
            _method: "DELETE",
        });
    }
    getCollectionQuestions(code) {
        return defaultRequest.get(`public/auto-kitchen/${code}/questions`);
    }
    updateCollection(id, data) {
        const params = {
            _method: "PATCH",
            data,
        };
        return defaultRequest.post(`auto-kitchen/backend/collections/${id}`, params);
    }
    delete(id) {
        return defaultRequest.post(`auto-kitchen/backend/collections/${id}`, {
            _method: "DELETE",
        });
    }
    fetchTest() {
        return defaultRequest.get(`auto-kitchen/backend/test/`);
    }
}
export default new AutoKitchen();
